// import { adminAxios } from "../../axiosConfig/InstancesAxios";

import axios from "axios";

const adminAxios = axios.create();
adminAxios.defaults.baseURL = 
// "https://be.garanty.tn:8443/garantie-api-admin/v1";
"http://38.242.231.224:8080/garantie-api-admin/v1";

adminAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
adminAxios.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  getAllFamily: () => {
    return adminAxios.post("/findEnableFamilies", {});
  },

  getFamilyById: (idFamily) => {
    return adminAxios.post("/getFamilybyId", null, { params: { idFamily } });
  },
  getFamilybyParentId: (idFamily) => {
    return adminAxios.post("/getFamilybyParentId", null, {
      params: { idFamily },
    });
  },
};
