import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import formuleService from "../../services/formule/formuleService";
import { savedataStepZero } from "../souscription/store/actionSouscription";
import { toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import { subDays } from "date-fns";
import Fr from "date-fns/locale/Fr";
import { saveFormule } from "../CreateUser/store/userAction";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input,
  Label,
} from "reactstrap";
registerLocale("Fr", Fr);
const SmartPhone = ({ idfamily, ...props }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [formuleList, setFormuleList] = useState([]);
  const [keyFormula, setKeyFormula] = useState(0);
  const [show, setShow] = useState(false);
  const [simulation, setSimulation] = useState([]);
  const [oppen, setOppen] = useState(false);
  const [volFormula, setVolFromula] = useState(null);
  const [fo, setFo] = useState(null);
  const [vol, setVol] = useState(false);
  const modaltoggle = () => {
    setOppen(!oppen);
  };
  const subscribe = (index) => {
    setFo(simulation[index]);
    let idF = simulation[index].fomula.idformula;
    // formuleService.getFormulaByParentId(idF).then((res) => {
    // if (res.status == 200) {
    //   setVolFromula(res.data);
    //   modaltoggle();
    //   console.log(simulation);
    // } else {
    dispatch(saveFormule(simulation[index]));
    if (localStorage.getItem("user")) {
      history.push({
        pathname: "/Souscription/Etape1",
      });
    } else {
      history.push({
        pathname: "/connexion",
      });
    }
    // }
    // });
  };
  const contunierVolOption = () => {
    if (vol) {
      dispatch(saveFormule({ ...fo, fomula: volFormula }));
      if (localStorage.getItem("user")) {
        history.push({
          pathname: "/Souscription/Etape1",
        });
      } else {
        history.push({
          pathname: "/connexion",
        });
      }
    } else {
      dispatch(saveFormule(fo));
      if (localStorage.getItem("user")) {
        history.push({
          pathname: "/Souscription/Etape1",
        });
      } else {
        history.push({
          pathname: "/connexion",
        });
      }
    }
  };
  const [formuleDto, setFormuleDto] = useState({
    amount: "",
    dateAchat: "",
    idFamille: idfamily,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormuleDto((formuleDto) => ({ ...formuleDto, [name]: value }));
  };
  useEffect(() => {
    formuleService.getAllFormulaById(idfamily).then((res) => {
      setFormuleList(
        res?.data?.sort((a, b) => a?.fomula?.price - b?.fomula?.price)
      );
    });
  }, []);

  const Continuer = async (e) => {
    e.preventDefault();
    if (formuleDto.amount && formuleDto.dateAchat) {
      localStorage.setItem("amount", formuleDto.amount);
      dispatch(savedataStepZero(formuleDto));
      await formuleService
        .calculateFormula(formuleDto)
        .then(async (response) => {
          if (response.status === 200) {
            setSimulation(response.data);
            setShow(true);
            window.scrollTo(0, 1350);
          } else {
            toast.error("Format incorrect du coût d'achat");
          }
        });
    } else if (!formuleDto.amount) {
      toast.error("Enter votre prix d'achat du produit");
    } else if (!formuleDto.dateAchat) {
      toast.error("Enter votre date d'achat du produit");
    }
  };

  const onClick = () => {
    history.push({ pathname: "/garanties/smartphone", state: idfamily });
  };

  return (
    <div>
        <div className="simula-div">
        <div className="row col-sm-12 simulation-prix">
          <div className="col-md-12 text-center">
            <h2 className="simulezz-prix">SIMULATION GRATUITE EN 2 CLICS</h2>

            <p className="col-8 dd">
              Précisez le prix de votre smartphone et la date de son achat pour
              découvrir la formule de garantie qui correspond à vos besoins et à
              votre profil.
            </p>
          </div>
        </div>
        <div className="row col-md-12 simulation-input">
          <div className="row col-md-8">
            <div className="col-md-4 aide">
              <input
                name="amount"
                type="number"
                className="input-prix"
                placeholder="Coût d'achat"
                value={formuleDto.amount}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 aide">
              <DatePicker
                selected={startDate}
                minDate={subDays(new Date(), 30)}
                maxDate={addDays(new Date(), 0)}
                dateFormat="dd/MM/yyyy"
                locale="Fr"
                className="input-prix"
                placeholderText="Date d’achat"
                value={formuleDto.dateAchat}
                onChange={(date) => {
                  setStartDate(date);
                  handleChange({ target: { name: "dateAchat", value: date } });
                }}
              />
            </div>
            <div className="col-md-4 aide div-btn-calculate">
              <div className="calcule-btn" id="continuer">
                <button
                  id="continuer"
                  className="calcule-btn"
                  type="submit"
                  onClick={Continuer}
                >
                  Continuer
                </button>
              </div>
            </div>
          </div>
        </div>

        </div>
      <div className="row col-sm-12 smartphone-body">
        <div
          className="row col-lg-12 col-md-12 formule-offer"
          style={!show ? { display: "none" } : { marginBottom: "4%" }}
        >
          <div className="row col-md-8">
            <div
              className="col-md-12 text-center"
              style={{ marginBottom: "30px" }}
            >
              <h2 className="simulezz-pix">
                LA MEILLEURE PROTECTION POUR VOTRE SMARTPHONES
              </h2>
            </div>
            <div className="formula-bloc">
              {simulation.map((el, i) => (
                <div className="formule-bloc">
                  <div
                    className="div-extend-formule"
                    style={{ height: "auto" }}
                  >
                    <h3 className="text-align">Formule</h3>
                    <img
                      className="img-formule-extend"
                      src={
                        `${process.env.PUBLIC_URL}/assets/images/` +
                        el?.fomula?.name +
                        ".svg"
                      }
                    />
                    <div
                      className="d-flex"
                      style={
                        i <= 0 && el?.fomula?.ceilingGuarantee === 300
                          ? { marginBottom: "25px" }
                          : i <= 0 && el?.fomula?.ceilingGuarantee === 600
                          ? { marginBottom: "48px" }
                          : i <= 1 && el?.fomula?.ceilingGuarantee <= 900
                          ? { marginBottom: "25px" }
                          : {}
                      }
                    >
                      <p
                        style={{
                          fontSize: "28px",
                          fontWeight: "bold",
                          marginTop: "15%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        {(el?.fomula?.price / 12)?.toFixed(3)} DT / mois
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "6px",
                        }}
                      >
                        {el?.fomula?.price?.toFixed(3)} DT / an
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                        Prix de smartphone de {el?.fomula?.minValueProd}
                        {el?.fomula?.maxValueProd >= 9998
                          ? " et +"
                          : `${-el?.fomula?.maxValueProd} DT`}
                      </p>

                      <div className="row col-md-12 detail-formule">
                        <p className="col-md-12" style={{ fontSize: "14px" }}>
                          1 Smartphone couvert
                        </p>
                      </div>

                      {el?.listBreakDown?.map((br, ke) =>
                        br.value ? (
                          <>
                            <div
                              key={ke}
                              className="row col-md-12 detail-formule"
                            >
                              <p
                                className="col-md-10"
                                style={{ fontSize: "12px" }}
                              >
                                {br.name}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                      <div className="row col-md-12 detail-formule">
                        <p className="col-md-10" style={{ fontSize: "12px" }}>
                          {el?.fomula?.nbrIntervYear > 1
                            ? "2 interventions /an"
                            : "1 intervention /an"}
                        </p>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <p className="col-md-12" style={{ fontSize: "14px" }}>
                          Assistance téléphonique 7J/7
                        </p>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <p
                          className="col-md-12"
                          style={{
                            fontSize: "14px",
                            color: "#e31279",
                            fontWeight: "700",
                          }}
                        >
                          Option VOL
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Plafond de garantie {el?.fomula?.ceilingGuarantee} DT
                      </p>
                    </div>
                    <div
                      className="simulation-btn"
                      key={i}
                      id="souscrire"
                      onClick={() => subscribe(i)}
                      style={{ marginTop: "20px" }}
                    >
                      <p
                        style={{
                          font: "normal normal bold 14px/40px Century Gothic",
                          fontWeight: "bold",
                          color: "#FFFFFF",
                        }}
                      >
                        Souscrire
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row col-lg-12 col-md-12 formule-offer">
          <div
            className="row col-lg-12 col-md-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="col-12 col-lg-12 col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="col-md-12 text-center">
                <h2 className="simulez-prix">
                  NOS FORMULES DE GARANTIES SMARTPHONES
                </h2>
                <p className="col-8" style={{ marginBottom: "3%" }}>
                  Nous vous proposons plusieurs formules de garantie selon le
                  prix d’achat de votre téléphone portable. Découvrez le détail
                  de chaque formule et assurez votre smartphone pour une
                  utilisation en toute sérénité !
                </p>
              </div>
              <div
                className="col-xl-12 col-lg-12 col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="form1" style={{ paddingRight: "1%" }}>
                  {formuleList.map((el, index) => (
                    <div
                      className={
                        keyFormula === index
                          ? "col-12 formula-select"
                          : "col-12 formula"
                      }
                      key={index}
                      onClick={() => {
                        setKeyFormula(index);
                      }}
                    >
                      <div
                        className="form"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          className="dispnone"
                          style={
                            keyFormula === index
                              ? {
                                  color: "#ffffff",
                                  paddingLeft: "5px",
                                  fontSize: "23px",
                                }
                              : {
                                  color: "#888888",
                                  paddingLeft: "5px",
                                  fontSize: "23px",
                                }
                          }
                        >
                          Formule
                        </h3>
                        <img
                          className={
                            keyFormula === index
                              ? "img-formula-select"
                              : "img-formula"
                          }
                          src={
                            `${process.env.PUBLIC_URL}/assets/images/` +
                            el.fomula?.name +
                            ".svg"
                          }
                        />
                      </div>
                      <div className="col-2 dispnone"></div>
                      <div className="col-4 text-formula">
                        <p
                          style={
                            keyFormula === index
                              ? { color: "#ffffff" }
                              : { color: "#888888" }
                          }
                        >
                          {(el?.fomula?.price / 12)?.toFixed(3)} DT / mois
                        </p>
                        <p
                          style={
                            keyFormula === index
                              ? { color: "#ffffff" }
                              : { color: "#888888" }
                          }
                        >
                          {el?.fomula?.price?.toFixed(3)} DT / an
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="form2" style={{ paddingLeft: "1%" }}>
                  <div className="div-extend-formule">
                    <h3 className="text-align">Formule</h3>
                    <img
                      className="img-formule-extend"
                      src={
                        `${process.env.PUBLIC_URL}/assets/images/` +
                        formuleList[keyFormula]?.fomula?.name +
                        ".svg"
                      }
                    />
                    <div className="d-flex">
                      <p
                        style={{
                          fontSize: "23px",
                          fontWeight: "bold",
                          marginTop: "15%",
                          fontFamily: "Century Gothic",
                        }}
                      >
                        {(formuleList[keyFormula]?.fomula?.price / 12).toFixed(
                          3
                        )}{" "}
                        DT / mois
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "6px",
                        }}
                      >
                        {formuleList[keyFormula]?.fomula?.price?.toFixed(3)} DT
                        / an
                      </p>
                      <p style={{ fontWeight: "bold", fontSize: "10px" }}>
                        Prix de smartphone de{" "}
                        {formuleList[keyFormula]?.fomula?.minValueProd}
                        {formuleList[keyFormula]?.fomula?.maxValueProd >= 9998
                          ? " et +"
                          : `${-formuleList[keyFormula]?.fomula
                              ?.maxValueProd} DT`}
                      </p>

                      <div className="row col-md-12 detail-formule">
                        <p className="col-md-12" style={{ fontSize: "14px" }}>
                          1 Smartphone couvert
                        </p>
                      </div>

                      {formuleList[keyFormula]?.listBreakDown?.map((br, ke) =>
                        br.value ? (
                          <>
                            <div
                              key={ke}
                              className="row col-md-12 detail-formule"
                            >
                              <p
                                className="col-md-10"
                                style={{ fontSize: "12px" }}
                              >
                                {br.name}
                              </p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                      <div className="row col-md-12 detail-formule">
                        <p className="col-md-10" style={{ fontSize: "12px" }}>
                          {formuleList[keyFormula]?.fomula?.nbrIntervYear > 1
                            ? "2 interventions /an"
                            : "1 intervention /an"}
                        </p>
                      </div>
                      <div className="row col-md-12 detail-formule">
                        <p
                          className="col-md-12"
                          style={{
                            fontSize: "14px",
                            color: "#e31279",
                            fontWeight: "700",
                          }}
                        >
                          Option VOL
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "17px",
                          fontWeight: "bold",
                          marginTop: "15px",
                        }}
                      >
                        Plafond de garantie{" "}
                        {formuleList[keyFormula]?.fomula?.ceilingGuarantee} DT
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#393c8f" }}>
        <div className="d-flex-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <h2
              style={{
                fontFamily: "Century Gothic",
                fontWeight: "bold",
                color: "#ffffff",
                fontSize: "24px",
              }}
            >
              CE QUI EST COUVERT
            </h2>
          </div>
          <div
            className="col-md-12"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0",
              padding: "0",
            }}
          >
            <div className="col-md-8 gr-container1">
              <div
                className="row col-md-12 margin0 d-flex-j"
                style={{ position: "relative" }}
              >
                <div className="row col-md-12" style={{ position: "relative" }}>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons 4.svg`}
                      />
                      <h3 className="text-img-couvert">
                        Oxydation &<br /> Humidité
                      </h3>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons 1.svg`}
                      />
                      <h3 className="text-img-couvert">Infiltration</h3>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons 2.svg`}
                      />
                      <h3 className="text-img-couvert">Écran Cassé</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-md-12 row-reverse margin"
                  style={{ position: "relative" }}
                >
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons 3.svg`}
                      />
                      <h3 className="text-img-couvert">
                        Face arrière
                        <br /> cassée
                      </h3>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons 5.svg`}
                      />
                      <h3 className="text-img-couvert">Casse accidentelle</h3>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex-a"
                    style={{
                      flexDirection: "column",
                      padding: "15px",
                      position: "relative",
                    }}
                  >
                    <img
                      className="img-couvert"
                      src={`${process.env.PUBLIC_URL}/assets/images/bg-icons.svg`}
                    />
                    <div
                      className="col-md-4 d-flex-a"
                      style={{
                        flexDirection: "column",
                        padding: "15px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{ width: "80px" }}
                        src={`${process.env.PUBLIC_URL}/assets/images/icons 6.svg`}
                      />
                      <h3 className="text-img-couvert">Court-Circuit</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-sm-12 garantie-Smart">
            <div className="col-sm-12">
              <div className="btn-hold">
                <p className="smartphone-garanty">
                  Enfin une Garantie smartphone... smart!
                </p>

                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="btn-decouvrezGarantie"
                    onClick={onClick}
                    id="decouvrezGarantie"
                  >
                    <p className="btn-garanty-smartphone">
                      DÉCOUVREZ NOTRE ASSURANCE SMARTPHONE
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={oppen}
        toggle={modaltoggle}
        centered
        style={{ display: "flex", justifyContent: "center", maxWidth: "750px" }}
      >
        <ModalHeader toggle={modaltoggle}>
          <div
            style={{
              font: "normal normal bold 25px/27px Century Gothic",
              color: "#0C015F",
            }}
          >
            Avant de continuer, souhaitez-vous ajouter l'option VOL?
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="form-row">
            <Col md="8 mb-4">
              <Col
                md={{
                  size: 10,
                }}
              >
                <Input
                  id="checkbox2"
                  type="checkbox"
                  onChange={() => {
                    setVol(!vol);
                  }}
                />
                <Label check className="text-popup">
                  Ajouter la protection contre le VOL
                </Label>
              </Col>
            </Col>
            <Col md="4 mb-4">
              <p className="text-popup">{volFormula?.vol_protection} DT/an</p>
            </Col>
            <Col md="6 mb-4">
              <p className="text-popup">
                Plafond de garantie VOL est {volFormula?.ceiling_guarantee_vol}
              </p>
            </Col>
          </div>
          <div>
            <div
              className="form1"
              style={{ paddingRight: "1%", width: "80% " }}
            >
              <div
                className={
                  "col-12 formula-select"
                  // : "col-12 formula"
                }
              >
                <div
                  className="form"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50%",
                  }}
                >
                  <h3
                    className="dispnone"
                    style={
                      {
                        color: "#ffffff",
                        paddingLeft: "5px",
                        fontSize: "23px",
                        display: "contents",
                      }
                      // : {
                      //     color: "#888888",
                      //     paddingLeft: "5px",
                      //     fontSize: "23px",
                      //   }
                    }
                  >
                    Formule
                  </h3>
                  <img
                    className={
                      "img-formula-select"
                      // : "img-formula"
                    }
                    style={{ width: "62%" }}
                    src={
                      `${process.env.PUBLIC_URL}/assets/images/` +
                      fo?.fomula?.name +
                      ".svg"
                    }
                  />
                </div>
                <div className="col-2 dispnone"></div>
                <div className="col-4 text-formula" style={{ display: "flex" }}>
                  <p
                    style={
                      { color: "#ffffff", fontWeight: "600" }
                      // : { color: "#888888" }
                    }
                  >
                    {vol
                      ? (volFormula?.price /12)?.toFixed(3)
                      : (fo?.fomula?.price /12)?.toFixed(3)}{" "}
                    DT / mois
                  </p>
                  <p
                    style={
                      { color: "#ffffff", fontWeight: "600" }
                      // : { color: "#888888" }
                    }
                  >
                    {vol
                      ? (volFormula?.price)?.toFixed(3)
                      : (fo?.fomula?.price)?.toFixed(3)}{" "}
                    DT / an
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={contunierVolOption}
            className="calcule-btn"
            style={{ width: "30%" }}
          >
            Continuer
          </Button>
        </ModalFooter>
      </Modal> */}
    </div>
  );
};

export default SmartPhone;
