import React from "react";
import * as IoIcons from "react-icons/io";
import "./miniCard.css";
function MiniCard({ text, addObject, color, icon, onClick }) {
  return (
    <div style={{ marginBottom: "30px", cursor:"pointer" }}onClick={onClick}>
      <div style={{ backgroundColor: color || "red" }} className="card-box-dash">
        <div style={{ marginRight: "2px" }}>
          <p style={{ font: "normal normal bold 16px/25px Century Gothic", width: "135px" }}>{text}</p>
          <div style={{ width: "50px", height: "3px", backgroundColor: "black" }}></div>
        </div>
        <img width="90" src={icon} />
      </div>
      {/* {addObject && (
        <div id="btn-add" onClick={addObject}>
          Ajouter un objet
        </div>
      )} */}
    </div>
  );
}

export default MiniCard;
