import axios from "axios";
const contarctAxios = axios.create();

contarctAxios.defaults.baseURL =
  // "https://be.garanty.tn:8443/garantie-api-contract/v1";
  "http://38.242.231.224:8080/garantie-api-contract/v1";

contarctAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

contarctAxios.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default {
  BAcheck: (idContract) => {
    return contarctAxios.post("/ba_check", null, { params: { idContract } });
  },
};
